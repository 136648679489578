/* FONT FACE -- MONTSERRAT */
@font-face {
  font-family: Montserrat;
  font-weight: 100;
  src: url("../fonts/Montserrat/static/Montserrat-Thin.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 100;
  font-style: italic;
  src: url("../fonts/Montserrat/static/Montserrat-ThinItalic.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 200;
  src: url("../fonts/Montserrat/static/Montserrat-ExtraLight.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 200;
  font-style: italic;
  src: url("../fonts/Montserrat/static/Montserrat-ExtraLightItalic.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 300;
  src: url("../fonts/Montserrat/static/Montserrat-Light.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Montserrat/static/Montserrat-LightItalic.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url("../fonts/Montserrat/static/Montserrat-Regular.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/Montserrat/static/Montserrat-Italic.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url("../fonts/Montserrat/static/Montserrat-Medium.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-style: italic;
  src: url("../fonts/Montserrat/static/Montserrat-MediumItalic.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url("../fonts/Montserrat/static/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/Montserrat/static/Montserrat-SemiBoldItalic.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url("../fonts/Montserrat/static/Montserrat-Bold.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/Montserrat/static/Montserrat-BoldItalic.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 800;
  src: url("../fonts/Montserrat/static/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 800;
  font-style: italic;
  src: url("../fonts/Montserrat/static/Montserrat-ExtraBoldItalic.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 900;
  src: url("../fonts/Montserrat/static/Montserrat-Black.ttf");
}
@font-face {
  font-family: Montserrat;
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/Montserrat/static/Montserrat-BlackItalic.ttf");
}
